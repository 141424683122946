<template>
  <div
    v-if="plugins.length"
    class="mb-5"
  >
    <v-card
      class="border-radius mb-4 pt-5 px-4 pb-4"
      :class="{ disabled: isLoading }"
    >
      <v-layout class="row wrap">
        <v-flex class="xs12 mb-4 title font-weight-regular">
          {{ $t("guestsAnswersLabel") }}
        </v-flex>
        <v-flex class="xs12 mb-3">
          <v-card class="mb-4 border-radius">
            <v-tabs
              v-model="tabs"
              slider-color="white"
              show-arrows
              height="60"
            >
              <v-tab
                v-for="(plugin, index) in plugins"
                :key="`tab-${index}`"
                :disabled="editMode"
              >
                <span class="text-none px-2">{{ plugin.name }}</span>
              </v-tab>
            </v-tabs>
          </v-card>
          <v-tabs-items v-model="tabs">
            <v-tab-item
              v-for="(plugin, index) in plugins"
              :key="`tab-item-${index}`"
            >
              <AttributesTabItem
                v-if="tabs === index"
                :plugin-id="plugin.id"
                :plugin-prefix="plugin.prefix"
                :disabled="!editMode"
                :loading="isLoading"
                @edit="editMode = true"
                @save="updateUser()"
                @cancel="closeEditMode()"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
import AttributesTabItem from "@/components/GroupUserPage/AttributesTabItem.vue";
import moment from "moment-timezone";

export default {
  data: () => ({
    isLoading: false,
    tabs: null,
    plugins: [],
    user: {},
    isGroupProductPurchased: false,
    editMode: false,
  }),
  computed: {
    activeGroup() {
      return this.$store.getters.activeGroup;
    },
    groupId() {
      return this.activeGroup.id;
    },
    groupSubscriptionProduct() {
      return this.activeGroup.subscription_product || "";
    },
    userId() {
      return this.$route.params.user_id;
    },
    requiredParams() {
      return this.groupId && this.userId ? true : false;
    },
    userAttributes() {
      return this.$store.getters.userPageAttributes;
    },
    attributeValues: {
      get() {
        return this.$store.getters.userPageAttributeValues;
      },
      set(val) {
        this.$store.dispatch("setUserPageAttributeValues", val);
      },
    },
    completeAttributeCategory: {
      get() {
        return this.$store.getters.completeAttributeCategory;
      },
      set(val) {
        this.$store.dispatch("setCompleteAttributeCategory", val);
      },
    },
    activeTabPlugin() {
      return this.plugins[this.tabs] || {};
    },
  },
  components: {
    AttributesTabItem,
  },
  watch: {
    requiredParams: {
      immediate: true,
      handler(val) {
        if (!val) return;

        this.fetchAllData();
      },
    },
    tabs() {
      this.completeAttributeCategory = false;
    },
  },
  methods: {
    async listGroupPluginsSilent(pluginPrefix) {
      const params = [
        this.groupId,
        {
          prefix: pluginPrefix,
        },
      ];

      return await this.$api.groupPlugins.list(...params);
    },
    async getUserSilent() {
      const params = [this.groupId, this.userId];

      const response = await this.$api.groupUsers.get(...params);

      this.user = response.data.data;

      if (this.user.group_product_purchased_at) {
        this.isGroupProductPurchased = true;
      }
    },
    async fetchAllData() {
      try {
        this.isLoading = true;

        await this.getUserSilent();

        const signupResponse = await this.listGroupPluginsSilent("signup");
        const questionwizardResponse = await this.listGroupPluginsSilent(
          "questionwizard",
        );

        this.isLoading = false;

        this.plugins = [
          ...signupResponse.data.data,
          ...questionwizardResponse.data.data,
        ];
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    async getAttributeCategorySilent(attributeId) {
      const params = [this.groupId, attributeId];

      const response = await this.$api.groupUserAttributes.get(...params);

      const category = response.data.data.categories[0];

      if (!category) {
        return null;
      }

      return category;
    },
    async completeAttributeCategorySilent() {
      const regex = /Attribute:(\d+)/;
      const match = this.groupSubscriptionProduct.match(regex);

      if (!match || !match[1]) {
        return null;
      }

      const attributeId = Number(match[1]);

      if (!attributeId) {
        return null;
      }

      const category = await this.getAttributeCategorySilent(attributeId);

      const params = [this.groupId, this.user.id, { category_id: category.id }];

      const response = await this.$api.groupUsers.complete_attribute_category(
        ...params,
      );

      if (response.status === 200) {
        this.isGroupProductPurchased = true;
      }
    },
    async updateUser() {
      try {
        const attributes = this.transformModelForSend(
          this.userAttributes,
          this.attributeValues,
        );

        const isCompleted = Object.values(attributes).every((val) => {
          if (
            val === undefined ||
            val === null ||
            val === "" ||
            val.length === 0
          ) {
            return false;
          }

          return true;
        });

        if (this.activeTabPlugin.prefix === "signup") {
          if (
            this.completeAttributeCategory &&
            !this.isGroupProductPurchased &&
            !isCompleted
          ) {
            this.$store.dispatch("addErrorNotification", {
              message: this.$t(
                "group_user_page.attributes_tab_item_signup_buy_group_product_validation_message",
              ),
            });

            return;
          }

          if (this.isGroupProductPurchased && !isCompleted) {
            this.$store.dispatch("addErrorNotification", {
              message: this.$t(
                "group_user_page.attributes_tab_item_signup_group_product_purchased_validation_message",
              ),
            });

            return;
          }
        }

        const specs = [this.userId, attributes];

        this.isLoading = true;

        await this.$api.users.update(...specs);

        if (
          this.activeTabPlugin.prefix === "signup" &&
          this.completeAttributeCategory &&
          !this.isGroupProductPurchased
        ) {
          await this.completeAttributeCategorySilent();
        }

        this.isLoading = false;
        this.editMode = false;

        this.$store.dispatch("addNotification", {
          message: this.$t("userUpdated"),
        });
      } catch (error) {
        if (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
        }
      }
    },
    transformModelForSend(attributes, values) {
      let answers = {};

      for (let i = 0; i < attributes.length; i++) {
        const answer = attributes[i];

        //convert values

        // plain value, use as is
        if (answer.type === "varchar" || answer.type === "text") {
          answers[answer.slug] = values[answer.slug];
          continue;
        }

        // date
        if (answer.type === "datetime") {
          answers[answer.slug] = values[answer.slug]
            ? moment
                .tz(values[answer.slug], this.userTimezone)
                .format("YYYY-MM-DD 00:00:00")
            : null;
          continue;
        }

        // option object instead of ID given, use only ID
        if (answer.type === "options" && !answer.is_collection) {
          answers[answer.slug] = values[answer.slug]
            ? values[answer.slug].id || values[answer.slug]
            : null;
          continue;
        }

        // collection of option objects or IDs given, use only IDs
        if (answer.type === "options" && answer.is_collection) {
          answers[answer.slug] = values[answer.slug]
            ? values[answer.slug].map((el) => el.id || el)
            : null;
          continue;
        }
      }

      return answers;
    },
    closeEditMode() {
      this.editMode = false;

      if (this.activeTabPlugin.prefix === "signup") {
        this.completeAttributeCategory = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.disabled {
  opacity: 0.3;
  pointer-events: none;
}
</style>
